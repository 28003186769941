<template>
  <div>
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory" name=“topNav”></topNav>

    <!--  ======  nav  ======  -->
    <div class="container">
      <div class="confirmorder">
        <div class="confirmorder-header">
          <span class="confirmorder-title">信息确认</span>
          <div class="confirmorder-header-all">
            <!-- 证书信息 -->
            <div class="confirmorder-header-top">
              <span>证书名称：{{ certificateMsg.name }}</span>
              <span>申报级别：{{ certificateMsg.declareLevel }}</span>
              <span>年份：{{ certificateMsg.year }}</span>
            </div>
          </div>
          <div class="confirmorder-header-pay">
            <span class="confirmorder-title">支付方式</span>
            <div class="confirmorder-header-pay-detail">
              <div :class="checkPay == 2 ? 'checkDiv' : ''" @click="checkPay = 2">
                <img src="../../assets/certificateList/icon-wx.png">
                <span>微信支付</span>
              </div>
              <!-- <div :class="checkPay == 1 ? 'checkDiv' : ''" @click="checkPay = 1">
                <img src="../../assets/certificateList/icon-zfb.png">
                <span>支付宝支付</span>
              </div> -->
              <!-- <div :class="checkPay == 2 ? 'checkDiv' : ''" @click="checkPay = 2">
                <img src="../../assets/certificateList/icon-yl.png">
                <span>网银支付</span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="confirmorder-bottom">
          <span class="confirmorder-title">需支付金额</span>
          <div>
            <span>证书价格</span>
            <span class="money">￥{{ totalAmount }}.00</span>
          </div>
          <div>
            <span>应付金额</span>
            <span class="money" style="color: #EB5757">￥{{ totalAmount }}.00</span>
          </div>

          <div class="confirmorder-bottom-yes">
            <el-checkbox v-model="checked">同意</el-checkbox>
            <span class="confirmorder-bottom-yes-yi">学院服务协议</span>
          </div>
          <el-button type="primary" @click="confirmPayment()">确定</el-button>
        </div>
      </div>
    </div>
    <footerBottom></footerBottom>


  </div>
</template>

<script>
import { httpGet, httpNPGet, httpPost } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from "@/components/footer/footer.vue"
var interval;
export default {
  name: "",
  data() {
    return {
      // 默认页面index
      pageIndex: 2,
      // 支付方式样式
      checkPay: 2,
      navList: ["首页", "选课中心", "帮助中心", "个人中心", "联系我们"],
      active: 0,
      translateX: 700,
      lineWidth: 32,
      imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
      // 浮动框
      xPos: document.body.clientWidth - 500,
      yPos: 100,
      xin: true,
      yin: true,
      step: 1,
      delay: 20,
      height: 0,
      heightSet: 0,
      widthSet: 0,
      yon: 0,
      xon: 0,
      pause: true,
      promptShow: true,
      // navStatus:false,
      //  -------
      checked: false,
      courseList: [
        {
          course: {}
        }
      ],
      certificateMsg: {
        name: "",
        declareLevel: "",
        year: "",
      },
      totalAmount: 0,
      orderSn: "",
    };
  },
  components: {
    // myVideo:Video
  },
  mounted() {
    // 证书信息赋值
    this.certificateMsg = JSON.parse(this.$route.query.certificateMsg)
    this.orderSn = JSON.parse(this.$route.query.data).orderSn
    this.totalAmount = JSON.parse(this.$route.query.data).totalAmount
  },
  methods: {
    // 确认支付按钮
    confirmPayment() {
      // console.log(this.$route.params)
      // 是否勾选
      if (this.checked) {
        // 微信支付请求
        if (this.checkPay == 2) {
          httpGet("/pay/wx/pc",
            {
              orderSn: this.orderSn,
            }, (res) => {
              if (res.data.type == 1) {
                this.$router.push(
                  {
                    name: "Payok",
                    params: {
                      orderSn: this.orderSn,
                      checkPay: this.payType,
                      certificateMsg: this.certificateMsg,
                      totalAmount: this.totalAmount,
                    }
                  }
                )
              } else {
                // console.log(res)
                this.$router.push({
                  name: "Orderpayment",
                  params: {
                    // 支付方式 0 微信 1 支付宝 2网银支付
                    checkPay: this.checkPay,
                    imgSrc: res.data.qrCode,
                    totalAmount: this.totalAmount,
                    orderSn: this.orderSn,
                    certificateMsg: this.certificateMsg,
                  }
                })
              }
            }, (err) => {

            })
        }
        // 支付宝支付流程
        else if (this.checkPay == 1) {
          httpGet("/pay/ali/pc",
            {
              orderSn: this.orderSn,
            }, (res) => {
              // console.log(res)
              this.$router.push({
                name: "Orderpayment",
                params: {
                  // 支付方式 0 微信 1 支付宝 2网银支付
                  checkPay: this.checkPay,
                  imgSrc: res.data.qrCode,
                  totalAmount: this.totalAmount,
                  orderSn: this.orderSn,
                  certificateMsg: this.certificateMsg,
                }
              })
            }, (err) => {

            })
        }
        // 银联支付流程
        else if (this.checkPay == 0) {
          httpGet("/pay/union/pc",
            {
              orderSn: this.orderSn,
            }, (res) => {
              // console.log(res)
              this.$router.push({
                name: "Orderpayment",
                params: {
                  // 支付方式 0 微信 1 支付宝 2网银支付
                  checkPay: this.checkPay,
                  imgSrc: res.data.qrCode,
                  totalAmount: this.totalAmount,
                  orderSn: this.orderSn,
                  certificateMsg: this.certificateMsg,
                }
              })
            }, (err) => {

            })
        }
      }
      // 没有勾选学院服务协议
      else {
        this.$message.error('请同意学院服务协议!')
      }
    },
    //鼠标移入效果
    clearFdAd() {
      clearInterval(interval);
      document.getElementsByClassName('prompt-img')[0].style.display = 'none';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'block';
    },
    //鼠标移出效果
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
      document.getElementsByClassName('prompt-img')[0].style.display = 'block';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'none';
    },
    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index) {
      this.active = index;

      // console.log('===========', index)
      this.setUnderLine();

      if (index === 4) {
        this.$router.push = "./videoplayback/videocatalog.vue";
      }
    },
  },
  components: {
    topNav: topNav,
    footerBottom
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 58px;
  height: 58px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //position: fixed;
  //top: 0;
  //left: 0;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */

    .nav-left h2 {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: 2px;
    }

    .nav-left span {
      width: 1px;
      height: 26px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      margin: 0 15px;
      opacity: 0.2;
    }
  }

  .nav-center {
    width: 600px;

    .nav-list {
      /* margin: 0 aucenterto;
      max-width: 600px; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
    }

    .nav-list>div {
      width: 600px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      border-right: solid 0.2px #d0d0d0;
    }

    .nav-list>div:last-child {
      border: none;
    }

    .nav-list--active {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
    }
  }

  .nav-line {
    margin: 0 auto;
  }

  .nav-line>div {
    /*width: 32px;*/
    height: 4px;
    // background-color: #1371E7;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
  }

  .nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }


}

.confirmorder {
  margin-top: 50px;
  background-color: #fff;
  padding: 0 32px 40px;
  margin-bottom: 50px;

  .confirmorder-title {
    display: block;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 600;
    padding-top: 32px;
    padding-bottom: 16px;
    color: #3E454D;
    text-align: left;
  }

  .confirmorder-header {
    .confirmorder-header-all {
      padding: 0 0 14px 0;
      border-bottom: 1px solid #EDEFF2;
      margin-top: 10px;

      .confirmorder-header-top {
        width: 100%;

        span {
          display: inline-block;
          margin-right: 20px;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #3E454D;
          align-items: left;
          padding-bottom: 5px;
        }
      }
    }

    .confirmorder-header-pay {

      .confirmorder-header-pay-detail {
        border-bottom: 1px solid #EDEFF2;
        display: flex;
        margin-top: 10px;
        padding-bottom: 20px;

        .checkDiv {
          //display: flex;
          text-align: center;
          line-height: 40px;
          //align-items: center;
          // width: 105px;
          height: 40px;
          border-radius: 2px 2px 2px 2px;
          border: 1.5px solid #1371E7;
          // color: white;
          margin-right: 20px;
          // background-color: #1371E7;
          cursor: pointer;

          span {
            padding-left: 5px;
          }

          img {
            //display: flex;
            width: 16px;
            height: 14px;
          }
        }

        div {
          box-sizing: border-box;
          //display: flex;
          text-align: center;
          line-height: 40px;
          //align-items: center;
          // width: 105px;
          padding: 0 10px;
          height: 40px;
          border-radius: 2px 2px 2px 2px;
          border: 1.5px solid #c7c7c7;
          margin-right: 20px;
          cursor: pointer;

          span {
            padding-left: 5px;
          }

          img {
            //display: flex;
            width: 16px;
            height: 14px;
          }
        }
      }
    }
  }

  .confirmorder-bottom {
    div {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
      text-align: left;
      margin-bottom: 5px;

      .money {
        padding-left: 10px;
        font-size: 18px;
      }
    }

    .confirmorder-bottom-yes {
      padding: 20px 0 20px;

      .confirmorder-bottom-yes-yi {
        font-family: PingFang SC-Regular, PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: #1371E7;
      }
    }

    .btn {
      display: block;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      width: 116px;
      height: 40px;
      background: #0073ff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      cursor: pointer;
    }
  }

}


.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}
</style>
